import BenefitTaxationSection from './BenefitTaxationSection';
import NotApplyToSection from './NotApplyToSection';
import NotCoveredCostsSection from './NotCoveredCostsSection';

const NewPolicyPage4 = () => {
  return (
    <>
      <NotApplyToSection />
      <NotCoveredCostsSection />
      <BenefitTaxationSection />
    </>
  );
};

export default NewPolicyPage4;
