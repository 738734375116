const InsuredAmountAndPremiumsSection = ({ data }) => {
  return (
    <div>
      <h2>Försäkringsbelopp och premier</h2>
      <p>
        Vid detta avtals ingående gäller en premie om{' '}
        <span style={{ backgroundColor: 'white' }}>
          {(
            data.premium.monthly / Number(data.answers.numberOfEmployees)
          ).toLocaleString('sv-SE', {
            maximumFractionDigits: 0,
          })}
        </span>{' '}
        SEK per försäkrad och månad. Vissa avvikelser kan förekomma vid
        fakturering av premiebeloppet med anledning av öresavrundning till hela
        kronor per försäkrad.
      </p>
    </div>
  );
};

export default InsuredAmountAndPremiumsSection;
