const InformationSectionPart1 = () => {
  return (
    <>
      <h2>
        Information om försäkringsgivaren, försäkringsförmedlaren och tillsyn
        m.m.
      </h2>
      <p>
        Försäkringsgivare är Eir Försäkring AB, org.nr. 559166-0617, Kungsgatan
        37, 111 56 Stockholm (försäkringsgivaren). Försäkringsgivaren kan
        kontaktas på info@eirforsakring.se eller telefon +47 911 78 000.
        Webbadress är www.eirforsakring.com.
      </p>
      <p>
        Avonova Hälsa AB, org.nr. 556500-6821, Fabriksgatan 4, 531 30 Lidköping
        (Avonova) är anknuten försäkringsförmedlare till försäkringsgivaren.
        Avonova kan kontaktas på forsakring@avonova.se och webbadress är
        www.avonova.se. Försäkringsgivaren är ansvarigt för ren
        förmögenhetsskada i enlighet med 4 kap. 16 § lagen om
        försäkringsdistribution. Avonova är registrerad i Bolagsverkets register
        för försäkringsklasserna Olycksfalls- och sjukförsäkring, Olycksfall
        (inkl. arbetsskador och yrkessjukdomar), Sjukdom, Annan
        förmögenhetsskada, Assistans, som anknuten försäkringsförmedlare vilket
        kan kontrolleras genom kontakt med Bolagsverket (Bolagsverket, 851 81
        Sundsvall, bolagsverket@bolagsverket.se, www.bolagsverket.se, tel.
        0771-670 670). Avonova tillhandahåller inte rådgivning som grundar sig
        på en opartisk och personlig analys som avses i lagen om
        försäkringsdistribution.
      </p>
      <p>
        Avonovas anställda, som säljer försäkring, erhåller en fast månadslön
        oavsett antalet sålda försäkringar. Avonova agerar för
        försäkringsgivarens räkning och är genom avtal förpliktigad att
        distribuera försäkringar uteslutande för försäkringsgivarens räkning.
        Avonovas ersättning för att distribuera försäkringen för
        försäkringsgivaren beräknas utifrån skadeutfall samt premievolym.
        Avonova har inget kvalificerat innehav i försäkringsgivaren, och
        försäkringsgivaren har inget kvalificerat innehav i Avonova.
      </p>
      <p>
        På begäran ska försäkringsgivaren lämna information om en anställd hos
        Avonova har rätt att distribuera försäkringar och om denna rätt är
        begränsad till visst slag av försäkring, någon eller några
        försäkringsklasser eller grupper av försäkringsklasser.
      </p>
    </>
  );
};

export default InformationSectionPart1;
