const InformationSectionPart2ForRenewedPolicy = () => {
  return (
    <>
      <p>
        Avonovas anställda, som säljer försäkring, erhåller en fast månadslön
        oavsett antalet sålda försäkringar. Avonova agerar för
        försäkringsgivarens räkning och är genom avtal förpliktigad att
        distribuera försäkringar uteslutande för försäkringsgivarens räkning.
        Avonovas ersättning för att distribuera försäkringen för
        försäkringsgivaren beräknas utifrån skadeutfall samt premievolym.
        Avonova har inget kvalificerat innehav i försäkringsgivaren, och
        försäkringsgivaren har inget kvalificerat innehav i Avonova.
      </p>
      <p>
        På begäran ska försäkringsgivaren lämna information om en anställd hos
        Avonova har rätt att distribuera försäkringar och om denna rätt är
        begränsad till visst slag av försäkring, någon eller några
        försäkringsklasser eller grupper av försäkringsklasser.
      </p>
      <p>
        Försäkringsgivaren står under tillsyn av Finansinspektionen.
        Försäkringsgivaren finns i Finansinspektionens företagsregister
        registrerat som riskbolag, skadeförsäkringar. Kontaktuppgifter till
        Finansinspektionen är; Box 7821, 103 97 Stockholm,
        finansinspektionen@fi.se, www.fi.se, tel. 08-408 980 00.
        Försäkringsgivaren och Avonova står även under Konsumentverkets tillsyn
        vad gäller marknadsföring och reklam (Konsumentverket, Box 48, 651 02
        Karlstad, konsumentverket@konsumentverket.se, www.konsumentverket.se,
        tel. 0771-42 33 00). Vidare står Försäkringsgivaren och Avonova under
        Integritetsskyddsmyndighetens tillsyn vad avser behandling av
        personuppgifter (Integritetsskyddsmyndigheten, Box 8114, 104 20
        Stockholm, imy@imy.se).
      </p>
    </>
  );
};

export default InformationSectionPart2ForRenewedPolicy;
