const CoverSectionPart2 = () => {
  return (
    <>
      <ul>
        <li>Behandling hos psykolog</li>
        <li>Behandling hos fysioterapeut/kiropraktor/naprapat</li>
        <li>Eftervård och medicinsk rehabilitering</li>
        <li>Rehabiliteringsplan</li>
        <li>Ortopedtekniska hjälpmedel</li>
        <li>Second opinion – förnyad bedömning</li>
      </ul>
    </>
  );
};

export default CoverSectionPart2;
