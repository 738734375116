import React from 'react';

const ComplainSection = () => {
  return (
    <>
      <h2>Klagomålshantering</h2>
      <p>
        Ta alltid först kontakt med den som handlagt ärendet. Ett samtal kan ge
        kompletterande uppgifter och eventuella missförstånd kan klaras upp. Om
        du fortsatt inte är nöjd lämnar handläggare ärendet till sin chef för en
        andra prövning.
      </p>
      <p>
        Avonovas klagomålsansvarig kan pröva de flesta typer av ärenden och
        granska såväl själva handläggningen som beslutet i ersättningsfrågan –
        men några undantag finns. Klagomålsansvarig prövar inte ärenden som är
        under prövning i domstol, skiljeförfarande, eller i annan extern
        ordning, t.ex. någon nämnd – och inte heller ärenden i vilka tvisten i
        sak har prövats i någon av dessa instanser.
      </p>
      <p>
        Vill du hellre vända dig till någon annan instans än klagomålsansvarig
        var vänlig se försäkringsvillkor eller hemsida www.avonova.se för
        ytterligare möjligheter.
      </p>
    </>
  );
};

export default ComplainSection;
