import ComplainSection from './ComplainSection';
import InformationSectionPart2ForRenewedPolicy from './InformationSectionPart2ForRenewedPolicy';

const RenewedPolicyPage6 = () => {
  return (
    <>
      <InformationSectionPart2ForRenewedPolicy />
      <ComplainSection />
    </>
  );
};

export default RenewedPolicyPage6;
