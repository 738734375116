const LiabilityPeriodSectionAndConnectingGroupMembersSection = () => {
  return (
    <div>
      <h2>Ansvarstid och anslutning av gruppmedlemmar</h2>
      <p>
        Försäkringsgivarens ansvar börjar att gälla från och med den
        avtalsperiod som anges i detta gruppavtal. För gruppmedlem som inträder
        i gruppen senare, räknas ansvaret från dagen efter inträdet i gruppen,
        under förutsättning att försäkringen kan beviljas enligt de villkor som
        gäller för gruppavtalet och vid var tid gällande tecknings- och
        hälsoprövningsregler. Förändringar i antalet gruppmedlemmar ska löpande
        rapporteras till Avonova. Premien kan därefter justeras efter antalet
        försäkrade i efterhand.
      </p>
      <p>
        Om inte gruppföreträdaren betalar premien inom 14 dagar efter utsänd avi
        gäller dock att försäkringsgivarens ansvar aldrig inträder
      </p>
    </div>
  );
};

export default LiabilityPeriodSectionAndConnectingGroupMembersSection;
