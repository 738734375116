const BenefitTaxationSection = () => {
  return (
    <>
      <h2>Förmånsbeskattning</h2>
      <p>
        Försäkringen är en förmån som ger dig snabb tillgång till vård och
        behandling av de skador som omfattas av försäkringen. Den är alltså ett
        komplement till befintlig hälso- och sjukvård och klassas skattemässigt
        som en förmån. Din arbetsgivare ska uppge detta i den kontrolluppgift
        som lämnas till Skatteverket.
      </p>
    </>
  );
};

export default BenefitTaxationSection;
