const HowToUseSection = ({ data }) => {
  return (
    <>
      <h2>Hur du använder försäkringen</h2>
      <p>
        Skadeanmälan görs genom detta tillvägagångssätt:{' '}
        <span style={{ backgroundColor: 'white' }}>
          {data.answers.productType === 'Employee reports' &&
            'Digital direktanmälan av enskild kundanställd på hemsida (www.avonova.se.)'}
          {data.answers.productType === 'Only manager/ HR reports' &&
            'Digital anmälan av HR/Chef via kontaktformulär på hemsida (www.avonova.se)'}
        </span>
      </p>
    </>
  );
};

export default HowToUseSection;
