import styled from 'styled-components';
import PropTypes from 'prop-types';

const PageFooter = ({ documentName = '', pageNumber = 0 }) => {
  return (
    <PageFooterContainer>
      <DocumentNameContainer>{documentName}</DocumentNameContainer>
      <PageNumberContainer>- {pageNumber} -</PageNumberContainer>
      <DummyContainer />
    </PageFooterContainer>
  );
};

PageFooter.propTypes = {
  documentName: PropTypes.string,
  pageNumber: PropTypes.number,
};

export default PageFooter;

const PageFooterContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 4rem;

  @media print {
    width: 100%;
    margin: 0 8mm;
    padding: 0;
  }
`;

const DocumentNameContainer = styled.div`
  flex: 1;
  color: #26dcba;
  font-size: 0.8rem;
  font-weight: 700;
`;

const PageNumberContainer = styled.div``;

const DummyContainer = styled.div`
  flex: 1;
`;
