const ProductDescriptionSection = ({ data }) => {
  return (
    <div>
      <h2>Produktbeskrivning</h2>
      <p>
        Försäkringen omfattar sjukdomar och besvär som uppkommit under
        försäkringstiden och där symtom inte har uppstått eller påvisats före
        försäkringens startdag. Försäkringen gäller för vård i Sverige hos
        privat vårdgivare som Avonova och dess partners har ingått avtal med.
        För en fullständig beskrivning av försäkringens omfattning hänvisas i
        första hand till vid var tid gällande försäkringsvillkor.
      </p>
      <p>
        Försäkringen tecknas för ett år i taget och förnyas automatiskt om det
        inte sägs upp av försäkringsgivaren eller försäkringstagaren.
        Försäkringsgivaren har rätt att inför varje års förnyelse ändra premie
        och försäkringsvillkor för försäkringen.
      </p>
      <p>
        Försäkringen gäller{' '}
        <span style={{ backgroundColor: 'white' }}>
          {data.answers.excessLevel === 0
            ? 'utan självrisk'
            : `med en självrisk om ${data.answers.excessLevel} SEK`}
        </span>
      </p>
      <p>
        Skadeanmälan görs genom detta tillvägagångssätt:{' '}
        <span style={{ backgroundColor: 'white' }}>
          {data.answers.productType === 'Employee reports' &&
            'Digital direktanmälan av enskild kundanställd på hemsida (www.avonova.se).'}
          {data.answers.productType === 'Only manager/ HR reports' &&
            'Digital anmälan av HR/Chef via kontaktformulär på hemsida (www.avonova.se).'}
        </span>
      </p>
    </div>
  );
};

export default ProductDescriptionSection;
