import ApplyWhereSection from './ApplyWhereSection';
import CoverSectionPartForRenewedPolicy from './CoverSectionPartForRenewedPolicy';
import HealthCareGuaranteeSection from './HealthCareGuaranteeSection';

const RenewedPolicyPage3 = () => {
  return (
    <>
      <ApplyWhereSection />
      <CoverSectionPartForRenewedPolicy />
      <HealthCareGuaranteeSection />
    </>
  );
};

export default RenewedPolicyPage3;
