import CoverSectionPart2 from './CoverSectionPart2';
import DeducibleSection from './DeducibleSection';
import HealthCareGuaranteeSection from './HealthCareGuaranteeSection';
import HowToUseSection from './HowToUseSection';
import LimitationsSection from './LimitationsSection';

const NewPolicyPage3 = ({ data }) => {
  return (
    <>
      <CoverSectionPart2 />
      <HealthCareGuaranteeSection />
      <HowToUseSection data={data} />
      <DeducibleSection data={data} />
      <LimitationsSection />
    </>
  );
};

export default NewPolicyPage3;
