const BackgroundAndPurposeSection = () => {
  return (
    <div>
      <h2>Bakgrund och syfte</h2>
      <p>
        Försäkringsgivaren tillhandahåller en försäkringsprodukt avseende
        sjukvårdsförsäkring. Enligt FAL är det en förutsättning för att
        gruppförsäkringen ska kunna tecknas att det finns ett underliggande
        gruppavtal med en företrädare för kunderna (jfr. FAL 19:3).
      </p>
    </div>
  );
};

export default BackgroundAndPurposeSection;
