import ApplyToWhoSection from './ApplyToWhoSection';
import ApplyWhenSection from './ApplyWhenSection';
import ChangesInInsuranceSectionPart2 from './ChangesInInsuranceSectionPart2';

const RenewedPolicyPage2 = () => {
  return (
    <>
      <ChangesInInsuranceSectionPart2 />
      <ApplyToWhoSection />
      <ApplyWhenSection />
    </>
  );
};

export default RenewedPolicyPage2;
