const PersonalInformationSection = () => {
  return (
    <>
      <h2>Personuppgifter</h2>
      <p>
        Vi värnar om dina personuppgifter och behandlar dem i enlighet med
        dataskyddsförordningen (GDPR) och övriga tillämpliga regler.
        Försäkringsgivaren behandlar de personuppgifter om dig som krävs för att
        fullgöra försäkringsavtalet. För information om försäkringsgivarens
        behandling av personuppgifter, läs mer i försäkringsgivarens
        integritetspolicy här: www.eirforsakring.com. För information om
        Avonovas behandling av personuppgifter, läs mer i Avonovas
        integritetspolicy här: www.avonova.se/integritetspolicy.
      </p>
    </>
  );
};

export default PersonalInformationSection;
