const CoverSectionPartForRenewedPolicy = () => {
  return (
    <>
      <h2>Vad omfattar försäkringen?</h2>
      <p>
        Försäkringen gäller tillhandahållande av nedanstående delar till följd
        av sjukdom eller besvär som uppkommit under försäkringstiden och där
        symtom inte uppstått eller påvisats före försäkringens startdag.
        Försäkringen omfattar vård enligt nedan som vid varje tidpunkt finns i
        privat regi i Sverige.
      </p>
      <ul>
        <li>Sjukvårdsrådgivning och vårdplanering </li>
        <li>Digital allmänläkare- och psykologtjänst</li>
        <li>Privat läkarvård</li>
        <li>Bedömningssamtal</li>
        <li>Operation och sjukhusvård</li>
        <li>Behandling hos psykolog</li>
        <li>Behandling hos fysioterapeut/kiropraktor/naprapat</li>
        <li>Eftervård och medicinsk rehabilitering</li>
        <li>Rehabiliteringsplan</li>
        <li>Ortopedtekniska hjälpmedel</li>
        <li>Second opinion – förnyad bedömning</li>
      </ul>
    </>
  );
};

export default CoverSectionPartForRenewedPolicy;
