const CongratulationsSectionPart2 = () => {
  return (
    <>
      <p>
        Försäkringen tecknas för ett år i taget och förnyas automatiskt om det
        inte sägs upp av försäkringsgivaren eller försäkringstagaren.
        Försäkringsgivaren har rätt att inför varje års förnyelse ändra premie
        och försäkringsvillkor för försäkringen. Det är din arbetsgivare som
        betalar för din försäkring. Du har när som helst rätt att avsäga dig din
        försäkring, även om den inte kostar dig som försäkrad någon premie. Du
        som anställd blir förmånsbeskattad för försäkringen enligt regler som
        trädde i kraft den 1 juli 2018. Den skattepliktiga förmånen av en
        sjukvårdsförsäkring beräknas till 60 procent av försäkringspremien. Vill
        du inte ta del av den försäkring som gruppföreträdaren tecknat åt dig
        kontaktar du själv gruppföreträdaren.
      </p>
    </>
  );
};

export default CongratulationsSectionPart2;
