import SigningSection from './SigningSection';

const Page4 = ({ data }) => {
  return (
    <>
      <SigningSection data={data} />
    </>
  );
};

export default Page4;
