const LimitationsSection = () => {
  return (
    <>
      <h2>Viktiga begränsningar i försäkringen</h2>
      <p>
        I försäkringen finns olika begränsningar. Av försäkringsvillkoren
        framgår bland annat att försäkringen inte gäller i vissa situationer.
        Nedan återfinns några av de viktigaste begränsningarna i försäkringen.
        Se villkoren för fullständig översikt.
      </p>
    </>
  );
};

export default LimitationsSection;
