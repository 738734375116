const ApplyToWhoSection = () => {
  return (
    <>
      <h2>Vem gäller försäkringen för?</h2>
      <p>
        Försäkringen gäller för dig som företaget och gruppföreträdaren har valt
        att teckna sjukvårdsförsäkringen för och som är fullt arbetsför, över 18
        år och bosatt i Sverige. Om du har för avsikt att vistas utomlands
        längre tid än tre (3) månader eller om utvandrar från Sverige ska ditt
        företag anmäla detta till oss och du kommer inte längre omfattas av
        försäkringen. Försäkringen gäller som längst till den månad du fyller 70
        år.
      </p>
    </>
  );
};

export default ApplyToWhoSection;
