import InsuredAmountAndPremiumsSection from './InsuredAmountAndPremiumsSection';
import ChoiceOfLawAndDisputeSection from './ChoiceOfLawAndDisputeSection';
import ConfidentialitySection from './ConfidentialitySection';
import ContractPeriodAndTerminationSection from './ContractPeriodAndTerminationSection';
import LiabilityPeriodSectionAndConnectingGroupMembersSection from './LiabilityPeriodSectionAndConnectingGroupMembersSection';

const Page3 = ({ data }) => {
  return (
    <>
      <InsuredAmountAndPremiumsSection data={data} />
      <LiabilityPeriodSectionAndConnectingGroupMembersSection />
      <ContractPeriodAndTerminationSection />
      <ConfidentialitySection />
      <ChoiceOfLawAndDisputeSection />
    </>
  );
};

export default Page3;
