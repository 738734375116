const ContractSection = ({ data }) => {
  const calculateNumberOfMonth = () => {
    const startDate = new Date(data.startDate);
    const endDate = new Date(data.endDate);

    let diff = (endDate.getTime() - startDate.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7 * 4;

    return Math.abs(Math.round(diff)) - 1;
  };

  calculateNumberOfMonth();

  return (
    <div>
      <h2>Avtalet</h2>
      <p>
        Eir Försäkring AB, org.nr. 559166-0617 (försäkringsgivaren) och
        {data && (
          <span style={{ backgroundColor: 'white' }}>
            &nbsp;{data.answers.companyName} {data.answers.companyReference}
            &nbsp;
          </span>
        )}
        (gruppföreträdaren, tillika arbetsgivaren och försäkringstagaren) har
        ingått följande gruppavtal om obligatorisk gruppförsäkring.
        Gruppföreträdaren svarar för att innehållet i detta gruppavtal inte
        strider mot de bestämmelser som vid var tid må vara gällande enligt
        interna regler hos arbetsgivare eller eventuellt föreliggande
        kollektivavtal som arbetsgivaren är bunden av.
      </p>
      <p>
        För försäkringen gäller detta gruppavtal, försäkringens
        försäkringsvillkor, försäkringsavtalslagen (FAL), gruppmedlemmens
        försäkringsbesked och tillämplig lag.
      </p>
      <p>
        Detta avtal har beteckningen:{' '}
        <span style={{ backgroundColor: 'white' }}>
          {`${data.answers.companyToInsure} ${data.answers.companyToInsureDescription}`}
        </span>
        <br />
        Gruppföreträdare är:{' '}
        <span style={{ backgroundColor: 'white' }}>
          {data.answers.groupRepTitle} {data.answers.groupRepName},{' '}
          {data.answers.groupRepEmail}, {data.answers.groupRepPhone}
        </span>
        <br />
        Detta avtal har följande avtalstid:{' '}
        <span style={{ backgroundColor: 'white' }}>
          {calculateNumberOfMonth()} månader
        </span>
        <br />
        Försäkrad grupp: {data.answers.companyToInsureDescription}
        <br />
        Följande personer har rätt att anslutas till försäkring: de som omfattas
        av begreppet Försäkrad grupp enligt ovan.
      </p>
      <p>
        Försäkringen börjar gälla från det den {data.startDate}. Om inget annat
        avtalas, börjar försäkringen att gälla från och med dagen efter det att
        gruppavtalet ingåtts. Huvudförfallodag framgår av försäkringsbeskedet.
      </p>
    </div>
  );
};

ContractSection.propTypes = {};

export default ContractSection;
