import CongratulationsSectionPart1 from './CongratulationsSectionPart1';
import PolicyInformationSection from './PolicyInformationSection';

const NewPolicyPage1 = ({ data }) => {
  return (
    <>
      <h1>
        Sjukvårdsförsäkring
        <br />
        Försäkringsbesked
        <p>2024</p>
      </h1>
      <PolicyInformationSection data={data} />
      <CongratulationsSectionPart1 />
    </>
  );
};

export default NewPolicyPage1;
