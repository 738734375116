const NotCoveredCostsSection = () => {
  return (
    <>
      <h2>Försäkringen täcker inte dessa kostnader</h2>
      <ul>
        <li>Resor eller logi i samband med vård, behandling eller operation</li>
        <li>Förlorad arbetsinkomst</li>
        <li>
          Kostnader i samband med sent avbokade eller uteblivna besök,
          behandlingar eller operationer
        </li>
      </ul>
    </>
  );
};

export default NotCoveredCostsSection;
