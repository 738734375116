import styled from 'styled-components';

const PolicyInformationSection = ({ data }) => {
  const invoiceInterval = numberOfInstallments => {
    switch (numberOfInstallments) {
      case '1':
        return 'År';
      case '2':
        return 'Halvår';
      case '4':
        return 'Kvartal';
      case '12':
        return 'Månad';
      default:
        return 'År';
    }
  };

  return (
    <Section>
      <p>
        Avtalsreferens:{' '}
        <span style={{ backgroundColor: 'white' }}>{data.policyNumber}</span>
        <br />
        Avtalspart:{' '}
        {data && (
          <span style={{ backgroundColor: 'white' }}>
            {data.answers.companyName} {data.answers.companyReference}
          </span>
        )}
        <br />
        Gruppföreträdare:{' '}
        {data && (
          <span style={{ backgroundColor: 'white' }}>
            {data.answers.groupRepTitle} {data.answers.groupRepName},{' '}
            {data.answers.groupRepEmail}, {data.answers.groupRepPhone}
          </span>
        )}
        <br />
        Avtalstid:{' '}
        {data && (
          <span style={{ backgroundColor: 'white' }}>
            {data.start} - {data.end}
          </span>
        )}
        <br />
        Huvudförfallodag:{' '}
        {data && <span style={{ backgroundColor: 'white' }}>{data.end}</span>}
        <br />
        Försäkrad grupp:{' '}
        {data && (
          <span style={{ backgroundColor: 'white' }}>
            {data.answers.companyToInsure}{' '}
            {data.answers.companyToInsureDescription}
          </span>
        )}
        <br />
        Antal försäkrade:{' '}
        {data && (
          <span style={{ backgroundColor: 'white' }}>
            {data.answers.numberOfEmployees}
          </span>
        )}
        <br />
        Premie per försäkrad och år:{' '}
        {data && (
          <span style={{ backgroundColor: 'white' }}>
            {(
              data.policyPremium.totalPremiumExcludingTax /
              Number(data.answers.numberOfEmployees)
            ).toLocaleString('sv-SE', { maximumFractionDigits: 0 })}{' '}
            kr
          </span>
        )}
        <br />
        Faktureringsintervall:{' '}
        {data && (
          <span style={{ backgroundColor: 'white' }}>
            {invoiceInterval(data.answers.numberOfInstallments)}
          </span>
        )}
        <br />
      </p>
      <p>
        Denna information är en kortfattad beskrivning av försäkringen. Du har
        rätt till denna information enligt lag och det är viktigt att du läser
        den tillsammans med övriga villkor som gäller för försäkringen. Spara
        gärna denna information. De fullständiga försäkringsvillkoren får du via
        din arbetsgivare eller via Avonovas hemsida www.avonova.se.
      </p>
    </Section>
  );
};

export default PolicyInformationSection;

const Section = styled.section`
  color: red;
  font-style: italic;
  border-top: solid 0.0625rem grey;
  border-bottom: solid 0.0625rem grey;
  padding: 1rem 0;
  margin-bottom: 2rem;
`;
