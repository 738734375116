import { useRouteMatch } from 'react-router-dom';

import { Page, PageWrapper } from '@eir/letter';
import { getOsaPolicyService, useApiRequest } from '@eir/services';

import NewPolicyPage1 from './components/NewPolicyPage1';
import NewPolicyPage2 from './components/NewPolicyPage2';
import NewPolicyPage3 from './components/NewPolicyPage3';
import NewPolicyPage4 from './components/NewPolicyPage4';
import NewPolicyPage5 from './components/NewPolicyPage5';
import NewPolicyPage6 from './components/NewPolicyPage6';
import RenewedPolicyPage1 from './components/RenewedPolicyPage1';
import RenewedPolicyPage2 from './components/RenewedPolicyPage2';
import RenewedPolicyPage3 from './components/RenewedPolicyPage3';
import RenewedPolicyPage4 from './components/RenewedPolicyPage4';
import RenewedPolicyPage5 from './components/RenewedPolicyPage5';
import RenewedPolicyPage6 from './components/RenewedPolicyPage6';
import PageContainer from '../../components/PageContainer';
import PageFooter from '../../components/PageFooter';
import PageHeader from '../../components/PageHeader';

const InsuranceLetterSwedenView = () => {
  const documentName = 'Försäkringsbesked 2.8';
  const match = useRouteMatch('/se/p/:policyId');
  const { data } = useApiRequest(
    getOsaPolicyService('AvonovaAPI', match.params.policyId)
  );

  return (
    data && (
      <PageWrapper>
        {data?.previousPolicyId ? (
          <>
            <Page
              pageHeader={<PageHeader />}
              content={
                <PageContainer>
                  <RenewedPolicyPage1 data={data} />
                </PageContainer>
              }
              pageFooter={
                <PageFooter documentName={documentName} pageNumber={1} />
              }
            />
            <Page
              pageHeader={<PageHeader />}
              content={
                <PageContainer>
                  <RenewedPolicyPage2 />
                </PageContainer>
              }
              pageFooter={
                <PageFooter documentName={documentName} pageNumber={2} />
              }
            />
            <Page
              pageHeader={<PageHeader />}
              content={
                <PageContainer>
                  <RenewedPolicyPage3 />
                </PageContainer>
              }
              pageFooter={
                <PageFooter documentName={documentName} pageNumber={3} />
              }
            />
            <Page
              pageHeader={<PageHeader />}
              content={
                <PageContainer>
                  <RenewedPolicyPage4 data={data} />
                </PageContainer>
              }
              pageFooter={
                <PageFooter documentName={documentName} pageNumber={4} />
              }
            />
            <Page
              pageHeader={<PageHeader />}
              content={
                <PageContainer>
                  <RenewedPolicyPage5 />
                </PageContainer>
              }
              pageFooter={
                <PageFooter documentName={documentName} pageNumber={5} />
              }
            />
            <Page
              pageHeader={<PageHeader />}
              content={
                <PageContainer>
                  <RenewedPolicyPage6 />
                </PageContainer>
              }
              pageFooter={
                <PageFooter documentName={documentName} pageNumber={6} />
              }
            />
          </>
        ) : (
          <>
            <Page
              pageHeader={<PageHeader />}
              content={
                <PageContainer>
                  <NewPolicyPage1 data={data} />
                </PageContainer>
              }
              pageFooter={
                <PageFooter documentName={documentName} pageNumber={1} />
              }
            />
            <Page
              pageHeader={<PageHeader />}
              content={
                <PageContainer>
                  <NewPolicyPage2 data={data} />
                </PageContainer>
              }
              pageFooter={
                <PageFooter documentName={documentName} pageNumber={2} />
              }
            />
            <Page
              pageHeader={<PageHeader />}
              content={
                <PageContainer>
                  <NewPolicyPage3 data={data} />
                </PageContainer>
              }
              pageFooter={
                <PageFooter documentName={documentName} pageNumber={3} />
              }
            />
            <Page
              pageHeader={<PageHeader />}
              content={
                <PageContainer>
                  <NewPolicyPage4 />
                </PageContainer>
              }
              pageFooter={
                <PageFooter documentName={documentName} pageNumber={4} />
              }
            />
            <Page
              pageHeader={<PageHeader />}
              content={
                <PageContainer>
                  <NewPolicyPage5 />
                </PageContainer>
              }
              pageFooter={
                <PageFooter documentName={documentName} pageNumber={5} />
              }
            />
            <Page
              pageHeader={<PageHeader />}
              content={
                <PageContainer>
                  <NewPolicyPage6 />
                </PageContainer>
              }
              pageFooter={
                <PageFooter documentName={documentName} pageNumber={6} />
              }
            />
          </>
        )}
      </PageWrapper>
    )
  );
};

export default InsuranceLetterSwedenView;
