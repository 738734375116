const ApplyWhereSection = () => {
  return (
    <>
      <h2>Var gäller försäkringen?</h2>
      <p>
        Försäkringen gäller för vård i Sverige hos privat vårdgivare som Avonova
        och dess partners har ingått avtal med.
      </p>
    </>
  );
};

export default ApplyWhereSection;
