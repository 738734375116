import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { configureAmplify } from '@eir/aws-authorization';

import GroupContractSwedenView from './views/GroupContractSweden/GroupContractSwedenView';
import InsuranceLetterSwedenView from './views/InsuranceLetterSweden/InsuranceLetterSwedenView';

import './styles/fonts.css';
import GlobalStyle from './styles/globalStyle';

configureAmplify(
  {
    endpointName: 'AvonovaAPI',
    endpointUrl: process.env.REACT_APP_AWS_ENDPOINT_URL,
  },
  true
);

const App = () => {
  return (
    <Router>
      <GlobalStyle />
      <Switch>
        <Route
          exact
          path='/se/q/:policyId'
          component={GroupContractSwedenView}
        />
        <Route
          exact
          path='/se/p/:policyId'
          component={InsuranceLetterSwedenView}
        />
      </Switch>
    </Router>
  );
};

export default App;
