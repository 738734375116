const ChangesInInsuranceSectionPart2 = () => {
  return (
    <>
      <h2>Inledande information</h2>
      <p>
        Försäkringen är en obligatorisk gruppförsäkring. De personer som
        omfattas av försäkringen är anställda vid det företag som tecknat
        försäkringen. Dessa personer är gruppmedlemmar och således de
        försäkrade. När vi i fortsättningen skriver ”du”, ”dig” och ”din” avser
        vi den person (gruppmedlem) som försäkringen gäller för.
      </p>
      <p>
        Till grund för försäkringen ligger ett gruppavtal. Gruppmedlemmarna är
        direkt anslutna till försäkringen på grund av gruppavtalet. Gruppavtalet
        ingås mellan försäkringsgivaren och försäkringstagaren, dvs. det företag
        som har tecknat försäkringen.
      </p>
      <p>
        Försäkringen tecknas för ett år i taget och förnyas automatiskt om det
        inte sägs upp av försäkringsgivaren eller försäkringstagaren.
        Försäkringsgivaren har rätt att inför varje års förnyelse ändra premie
        och försäkringsvillkor för försäkringen. Det är din arbetsgivare som
        betalar för din försäkring. Du har när som helst rätt att avsäga dig din
        försäkring, även om den inte kostar dig som försäkrad någon premie. Du
        som anställd blir förmånsbeskattad för försäkringen enligt regler som
        trädde i kraft den 1 juli 2018. Den skattepliktiga förmånen av en
        sjukvårdsförsäkring beräknas till 60 procent av försäkringspremien. Vill
        du inte ta del av den försäkring som gruppföreträdaren tecknat åt dig
        kontaktar du själv gruppföreträdaren.
      </p>
    </>
  );
};

export default ChangesInInsuranceSectionPart2;
