const NotApplyToSection = () => {
  return (
    <>
      <h2>Försäkringen gäller inte för:</h2>
      <ul>
        <li>
          Sjukdom och besvär eller följd av sådana tillstånd där symtomen visat
          sig före försäkringens startdag
        </li>
        <li>
          Vård som inte på förhand har godkänts av försäkringsgivaren eller dess
          partners
        </li>
        <li>
          Akut sjukvård, akuta transporter och kostnader uppkomna i samband med
          akut vård
        </li>
        <li>
          Förebyggande behandling, vaccinationer, hälsoundersökningar och
          screeningundersökningar.
        </li>
        <li>Behandling av kroniska sjukdomar</li>
        <li>
          Anmälningspliktig sjukdom som finns reglerad i lag eller följder av
          sådan sjukdom
        </li>
        <li>
          Kosmetiska behandlingar och operationer eller följder därav som inte
          är en följd av ersättningsberättigad sjukdom eller olycksfallsskada.
        </li>
        <li>
          Kontroll och behandling som föranleds av graviditet, förlossning,
          abort, sterilisering
        </li>
        <li>
          Fertilitetsutredning, behandling av infertilitet, könskorrigerande
          behandlingar
        </li>
        <li>
          Utredning och behandling av neuropsykiatrisk störning (t.ex. ADHD,
          autism)
        </li>
        <li>
          Behandlings- eller läkemedelskostnader på grund av övervikt eller
          överviktoperation
        </li>
        <li>Utredning och behandling av snarkning och sömnapné</li>
        <li>
          Undersökning och behandling av tänder eller synfel, oavsett orsak
        </li>
      </ul>
    </>
  );
};

export default NotApplyToSection;
