import DeducibleSection from './DeducibleSection';
import HowToUseSection from './HowToUseSection';
import LimitationsSection from './LimitationsSection';
import NotApplyToSection from './NotApplyToSection';

const RenewedPolicyPage4 = ({ data }) => {
  return (
    <>
      <HowToUseSection data={data} />
      <DeducibleSection data={data} />
      <LimitationsSection />
      <NotApplyToSection />
    </>
  );
};

export default RenewedPolicyPage4;
