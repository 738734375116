const PartiesCommitmentsPart1Section = () => {
  return (
    <div>
      <h2>Parternas åtaganden</h2>
      <p>
        Försäkringsgivaren åtar sig att i enlighet med detta gruppavtal
        tillhandahålla försäkringen till alla som är anställda hos
        gruppföreträdaren eller den grupp som särskilt anges i detta gruppavtal.
        Försäkringsgivaren har utsett Avonova till samarbetspartner och
        förmedlare av denna försäkring.
      </p>
      <p>
        Försäkringsgivaren ska tillhandahålla sjukvårdsförsäkring till de
        försäkringsberättigade personer som uppfyller kraven för att få omfattas
        av försäkringen. Försäkringsgivaren eller av försäkringsgivaren utsedd
        samarbetspartner (Avonova) ska dessutom handha försäkringsadministration
        och kundtjänst samt tillhandahålla villkor, försäkringsbesked och
        informationsmaterial.
      </p>
      <p>
        Gruppföreträdaren ska, till försäkringsgivaren eller av
        försäkringsgivaren utsedd samarbetspartner (Avonova), löpande
        tillhandahålla uppgifter om antalet försäkrade i den utsträckning och
        omfattning detta är nödvändigt för rätt fullgörande av detta avtal.
        Gruppföreträdaren ska även tillse att premieinbetalning sker mellan
        Gruppföreträdaren och försäkringsgivaren, eller av försäkringsgivaren
        utsedd samarbetspartner (Avonova).
      </p>
      <p>
        Gruppföreträdaren ska tillhandahålla handlingar till gruppmedlemmarna
        och informera om försäkringen i enlighet med de anvisningar som lämnas
        av försäkringsgivaren eller av försäkringsgivaren utsedd
        samarbetspartner (Avonova).
      </p>
    </div>
  );
};

export default PartiesCommitmentsPart1Section;
