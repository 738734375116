const GroupRepresentativesAndGroupMembersSection = () => {
  return (
    <>
      <h2>Gruppföreträdare, gruppmedlemmar m.m.</h2>
      <p>Eir är försäkringsgivare.</p>
      <p>
        Gruppföreträdaren (arbetsgivare tillika försäkringstagaren) företräder
        och representerar den försäkrade gruppen, dvs. de anställda tillika
        gruppmedlemmarna och de försäkrade.
      </p>
      <p>
        Gruppmedlemmarna är direkt anslutna till försäkringen med anledning av
        detta gruppavtal.
      </p>
    </>
  );
};

export default GroupRepresentativesAndGroupMembersSection;
