const ContractPeriodAndTerminationSection = () => {
  return (
    <div>
      <h2>Avtalstid och uppsägning</h2>
      <p>
        Avtalet träder i kraft när angiven avtalsperiod startar. Avtalet förnyas
        därefter med ett (1) år i sänder om det inte sägs upp av någon av
        parterna. Uppsägning ska vara skriftlig. Vid försäkringstagarens
        uppsägning upphör försäkringen för samtliga gruppmedlemmar vid den
        tidpunkt som anges i uppsägningen, dock tidigast en månad efter att
        uppsägningen inkommit till Avonova.
      </p>
    </div>
  );
};

export default ContractPeriodAndTerminationSection;
