const HealthCareGuaranteeSection = () => {
  return (
    <>
      <h2>Vårdgaranti</h2>
      <p>
        Försäkringen innehåller en vårdgaranti. Från det att din skada är
        godkänd av försäkringsgivaren garanteras du, när det omfattas av
        försäkringen och är medicinskt motiverat:
      </p>
      <ul>
        <li>
          en första medicinsk rådgivning av medicinskt legitimerad personal inom
          7 arbetsdagar, och
        </li>
        <li>
          tid till operation inom 20 arbetsdagar från det att operationen är
          godkänd av försäkringsgivaren.
        </li>
      </ul>
      <p>
        Garantin gäller inte om den försäkrade inte kan opereras av medicinska
        skäl, uteblir från inbokad behandling, tackar nej till inbokad tid eller
        genom överenskommelse kommer överens om senare bokad tid för behandling.
        Om vi inte kan uppfylla vårdgarantin utgår ersättning till
        försäkringstagaren, d.v.s. företaget du är anställd i.
      </p>
    </>
  );
};

export default HealthCareGuaranteeSection;
