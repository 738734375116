import styled from 'styled-components';

const SigningSection = ({ data }) => {
  return (
    <SigningContainer>
      <NumberOfCopies>
        Detta avtal har upprättats i två (2) exemplar, varav parterna har tagit
        del av varsitt.
      </NumberOfCopies>
      <CityContainer>Ort:</CityContainer>
      <DateContainer>Datum:</DateContainer>
      <SignaturesContainer>
        Eir Försäkring AB
        <hr />
        <span>Namn:</span>
        <hr />
        <span>Namn:</span>
      </SignaturesContainer>
      <SignaturesContainer>
        {data && (
          <span style={{ backgroundColor: 'white' }}>
            {data.answers.groupRepName}
          </span>
        )}
        <hr />
        <span>Namn:</span>
        <hr />
        <span>Namn:</span>
      </SignaturesContainer>
    </SigningContainer>
  );
};

export default SigningSection;

const SigningContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin-top: 4rem;
`;

const CityContainer = styled.div`
  grid-column: 1 / span 2;
  height: 3rem;
`;

const DateContainer = styled.div`
  grid-column: 1 / span 2;
  height: 3rem;
`;

const SignaturesContainer = styled.div`
  display: flex;
  flex-direction: column;

  hr {
    margin-top: 4rem;
    margin-bottom: 1.5rem;
    height: 0.0625rem;
    border-width: 0;
    background-color: #d3d3d3;
  }
`;

const NumberOfCopies = styled.p`
  grid-column: 1 / span 2;
`;
