const CongratulationsSectionPart1 = () => {
  return (
    <>
      <h2>Grattis!</h2>
      <p>
        Din arbetsgivare har tecknat en försäkring för dig. Försäkringen är en
        obligatorisk gruppförsäkring. De personer som omfattas av försäkringen
        är anställda vid det företag som tecknat försäkringen. Dessa personer är
        gruppmedlemmar och således de försäkrade. När vi i fortsättningen
        skriver ”du”, ”dig” och ”din” avser vi den person (gruppmedlem) som
        försäkringen gäller för.
      </p>
      <p>
        Till grund för försäkringen ligger ett gruppavtal. Gruppmedlemmarna är
        direkt anslutna till försäkringen på grund av gruppavtalet. Gruppavtalet
        ingås mellan försäkringsgivaren och försäkringstagaren, dvs. det företag
        som har tecknat försäkringen.
      </p>
    </>
  );
};

export default CongratulationsSectionPart1;
