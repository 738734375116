import ApplyWhereSection from './ApplyWhereSection';
import ApplyWhenSection from './ApplyWhenSection';
import CongratulationsSectionPart2 from './CongratulationsSectionPart2';
import CoverSectionPart1 from './CoverSectionPart1';
import ApplyToWhoSection from './ApplyToWhoSection';

const NewPolicyPage2 = () => {
  return (
    <>
      <CongratulationsSectionPart2 />
      <ApplyToWhoSection />
      <ApplyWhenSection />
      <ApplyWhereSection />
      <CoverSectionPart1 />
    </>
  );
};

export default NewPolicyPage2;
