const ConfidentialitySection = () => {
  return (
    <div>
      <h2>Sekretess</h2>
      <p>
        Parterna förbinder sig att inte utan den andre partens medgivande till
        tredje man under avtalstiden eller därefter utlämna sådana uppgifter om
        den andres verksamhet som kan vara att betrakta som affärs- eller
        yrkeshemlighet. Detta hindrar dock inte parterna att informera om att
        samarbete dem emellan äger rum.
      </p>
    </div>
  );
};

export default ConfidentialitySection;
