const ApplyWhenSection = () => {
  return (
    <>
      <h2>När gäller försäkringen?</h2>
      <p>
        Försäkringen gäller för försäkringsfall som inträffar under
        försäkringsperioden, alltså den tid som försäkringen är i kraft. Om
        inget annat avtalats börjar försäkringen gälla dagen efter den dag ditt
        företag ingått gruppavtalet. För anställda som inträder i gruppen
        senare, räknas ansvaret från dagen efter inträdet i gruppen.
      </p>
    </>
  );
};

export default ApplyWhenSection;
