const ChoiceOfLawAndDisputeSection = () => {
  return (
    <div>
      <h2>Lagval och tvist</h2>
      <p>
        Tvist som uppstår mellan parterna i anledning av detta avtal ska
        slutligt avgöras genom skiljedomsförfarande administrerat av Stockholms
        Handelskammares Skiljedomsinstitut (SCC). Regler för Förenklat
        Skiljeförfarande ska tillämpas om inte SCC med beaktande av målets
        svårighetsgrad, tvisteföremålets värde och övriga omständigheter
        bestämmer att Skiljedomsregler ska tillämpas. I sistnämnda fall ska SCC
        också bestämma om skiljenämnden ska bestå av en eller tre skiljemän.
        Skiljeförfarandet ska äga rum i Stockholm. Språket för förfarandet ska
        vara svenska. Svensk rätt ska tillämpas på avtalet.
      </p>
    </div>
  );
};

export default ChoiceOfLawAndDisputeSection;
