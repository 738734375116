import BenefitTaxationSection from './BenefitTaxationSection';
import InformationSectionPart1ForRenewedPolicy from './InformationSectionPart1ForRenewedPolicy';
import NotCoveredCostsSection from './NotCoveredCostsSection';
import PersonalInformationSection from './PersonalInformationSection';

const RenewedPolicyPage5 = () => {
  return (
    <>
      <NotCoveredCostsSection />
      <BenefitTaxationSection />
      <PersonalInformationSection />
      <InformationSectionPart1ForRenewedPolicy />
    </>
  );
};

export default RenewedPolicyPage5;
